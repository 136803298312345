import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useStoreActions, useStoreState } from 'easy-peasy'

import { EMPTY_SCREEN_REASONS } from 'components/DropzoneEmptyScreen/DropzoneEmptyScreen.constants'
import { NOTIFICATION_TYPES } from 'components/Notification'
import UploadPicture from 'components/UploadPicture/UploadPicture'
import api from 'utils/api'

import ReplacePicturesModalWrapper from './ReplacePicturesModal.styled'

// TODO: think about combining with ReplaceImage
const ReplacePicturesModal = ({ onClose, onCompleted }) => {
  const picturesLoading = useStoreState((state) => state.pictures.isLoading)
  const updatePicture = useStoreActions((actions) => actions.pictures.updatePicture)
  const setIsLoading = useStoreActions((actions) => actions.pictures.setIsLoading)

  useEffect(() => {
    setIsLoading(false)
  }, [])

  const onDrop = useCallback(async (acceptedFiles) => {
    if (!acceptedFiles.length) return

    setIsLoading(true)

    onCompleted({
      type: NOTIFICATION_TYPES.INFO,
      message: 'Preparing pictures replace',
    })

    const fileNames = acceptedFiles.map((file) => file.name)

    const response = await api.post({
      url: `/api/v2/user/pictures/search`,
      data: { filter: { file_name: fileNames } },
    })

    const pictures = response.data.data

    const filesToReplace = acceptedFiles.map((file) => {
      const picture = pictures.find((p) => p.fileName === file.name)
      if (picture) {
        return [file, picture]
      }

      return false
    }).filter(Boolean)

    if (filesToReplace.length) {
      onCompleted({
        type: NOTIFICATION_TYPES.INFO,
        message: `Founded ${filesToReplace.length} pictures to replace`,
      })

      for (const [file, picture] of filesToReplace) {
        await updatePicture({ picture: { id: picture.id, image: file } })
      }

      onCompleted({
        type: NOTIFICATION_TYPES.SUCCESS,
        message: `${filesToReplace.length} pictures replaced`,
      })
    } else {
      onCompleted({
        type: NOTIFICATION_TYPES.WARNING,
        message: 'Nothing to replace from selected pictures',
      })
    }

    setIsLoading(false)

    onClose()

  }, [])

  return (
    <ReplacePicturesModalWrapper>
      <div className={'content-wrap'}>
        <UploadPicture
          onDrop={onDrop}
          isLoading={picturesLoading}
          multiple={true}
          reason={EMPTY_SCREEN_REASONS.BULK_REPLACE}
        />
      </div>
    </ReplacePicturesModalWrapper>
  )
}

ReplacePicturesModal.propTypes = {
  onClose: PropTypes.func,
  onCompleted: PropTypes.func,
}

export default ReplacePicturesModal
