import { BOOK_SECTION_TYPES } from 'utils/constants'

// TODO: move constants to .constants and helper functions to .utils
export const FIRST_INSIDE_COVER_INDEX = -1
export const COVER_INDEX = -2
export const LAST_INSIDE_COVER_INDEX = -3
export const BACK_COVER_INDEX = -4
export const INSIDE_COVER_LABEL = 'Inside Cover'

export const getSingleImagePagePicture = (page) => {
  return page?.sections?.find(({ sectionType }) => sectionType === BOOK_SECTION_TYPES.IMAGE)?.picture
}
