import { CancelToken } from 'axios'
import { thunk, thunkOn } from 'easy-peasy'
import { omit } from 'lodash'

import { getUpdateSectionConfig } from 'containers/User/Books/Books_v3.service'
import api from 'utils/api'

import { getPageRenderKey, prepareBookForUpdate } from './Book.utils'

const getNextPageKey = (pages, pageToDeleteIndex) => {
  const nextActivePageIndex = pageToDeleteIndex + 1
  const nextPage = pages[nextActivePageIndex] || pages[pageToDeleteIndex - 1] || null
  return getPageRenderKey(nextPage)
}

const getBookApiUrl = {
  book: (bookId) => `/api/v3/user/books/${bookId}`,
  books: () => `/api/v3/user/books`,
  pictures: () => `/api/v2/user/pictures`,
  deletePage: (bookId, pageId) => `/api/v3/user/books/${bookId}/pages/${pageId}`,
}

let updateBookTokens = new Map()

const bookService_v3 = {
  createBook_v3: thunk(async (actions, book) => {
    actions.setIsLoading_v3(true)
    // axios config object
    const config = {
      method: 'post',
      url: getBookApiUrl.books(),
      data: {
        ...book,
      },
    }

    const { error, response } = await api.handleRequest(config)

    if (error) {
      actions.setError_v3(error)
      return { error }
    }

    if (response) {
      actions.setBook_v3(response.data.data)
      return response.data.data
    }
  }),

  readBook_v3: thunk(async (actions, id) => {
    actions.setIsLoading_v3(true)
    // axios config object
    const config = {
      method: 'get',
      url: getBookApiUrl.book(id),
    }

    const { error, response } = await api.handleRequest(config)
    actions.setIsLoading_v3(false)
    if (error) {
      actions.setError_v3(error)
      return { error }
    }

    if (response) {
      actions.setBook_v3(response.data.data)
      actions.setIsLoading_v3(false)
      return response.data.data
    }
  }),
  updateBookSection_v3: thunk(async (actions, { bookId, sectionId, payload }) => {
    actions.setBookSection_v3({ nextSectionData: payload, nextSectionId: sectionId })

    if (updateBookTokens.has(bookId)) {
      updateBookTokens.get(bookId).cancel()
    }
    updateBookTokens.set(bookId, CancelToken.source())

    const config = getUpdateSectionConfig(bookId, sectionId, payload, updateBookTokens)
    const { error, response } = await api.handleRequest(config)

    if (error) {
      actions.setError_v3(error)
      return { error }
    }

    if (response) {
      const data = response.data.data
      actions.setBookSection_v3({ nextSectionData: { ...data }, nextSectionId: sectionId })
      return response.data.data
    }
  }),
  updateBook_v3: thunk(async (actions, book, helpers) => {
    const activeBook = helpers.getState().activeBook_v3
    const pagesOrderIds = helpers.getState().orderedPageIds_v3

    const updatedBook = prepareBookForUpdate({
      ...activeBook,
      ...book,
    }, pagesOrderIds)

    const bookId = activeBook.id
    if (updateBookTokens.has(bookId)) {
      updateBookTokens.get(bookId).cancel()
    }
    updateBookTokens.set(bookId, CancelToken.source())

    // axios config object
    const config = {
      method: 'put',
      url: getBookApiUrl.book(bookId),
      data: {
        book: {
          ...updatedBook,
        },
      },
      cancelToken: updateBookTokens.get(bookId).token,
    }

    const { error, response } = await api.handleRequest(config)

    if (error) {
      error.isV2 = true
      actions.setError_v3(error)
      return { error }
    }

    if (response) {
      actions.setBook_v3(response.data.data)
      return response.data.data
    }
  }),

  bulkUpdateBookPictures_v3: thunk(async (actions, { ids, picture: { image, replaceTags, ...picture } }) => {
    try {
      const payload = { picture, ids }
      if (replaceTags) {
        payload.picture._replace_tags = true
      }
      actions.setIsLoading_v3(true)
      const { data: { data: updatedPictures } } = await api.put({
        // this is fix for updating before new data structure.
        // seems like it will be fixed after we will migrate on new data structure
        url: getBookApiUrl.pictures(),
        data: payload,
      })
      return updatedPictures
    } catch (error) {
      error.isV2 = true
      actions.setError_v3(error)

    } finally {
      actions.setIsLoading_v3(false)
    }
  }),

  deletePages_v3: thunk(async (actions, selectedPages, { getState }) => {
    // TODO: pay attention after successful migration to use optimistic UI approach
    actions.setIsLoading_v3(true)

    const activeBook = getState().activeBook_v3

    const handleError = (error) => {
      actions.setIsLoading_v3(false)
      actions.setError_v3(error)
      return { error }
    }

    const handleSuccess = (response) => {
      actions.setIsLoading_v3(false)
      actions.setBook_v3(response.data.data)
      return response.data.data
    }

    if (selectedPages.length === 1) {
      const [pageId] = selectedPages
      const currentPageIndex = activeBook.pages.findIndex((page) => page.id === pageId)
      const nextPageKey = getNextPageKey(activeBook.pages, currentPageIndex)

      const pages = [
        ...activeBook.pages.slice(0, currentPageIndex),
        ...activeBook.pages.slice(currentPageIndex + 1),
      ]

      actions.setBook_v3({ ...activeBook, pages })
      actions.setActivePageKey_v3(nextPageKey)

      const config = {
        method: 'delete',
        url: getBookApiUrl.deletePage(activeBook.id, pageId),
      }

      const { error, response } = await api.handleRequest(config)

      if (error) {
        return handleError(error)
      }

      if (response) {
        return handleSuccess(response)
      }
    }

    if (selectedPages.length > 1) {
      const lastPageToDeleteIndex =
        activeBook.pages.findIndex((page) => page.id === selectedPages[selectedPages.length - 1])
      const nextActivePageKey = getNextPageKey(activeBook.pages, lastPageToDeleteIndex)
      const pagesToUpdate = activeBook.pages
        .filter((page) => !selectedPages.includes(page.id))
        .map((page) => ({
          ...omit(page, 'id'),
          sections: page.sections.map((section) => section.picture?.id ? section : section),
        }))
      await actions.updateBook_v3({ pages: pagesToUpdate })
      actions.setActivePageKey_v3(nextActivePageKey)
    }
    actions.setIsLoading_v3(false)
  }),

  onBookUpdate: thunkOn(
    (actions) => [
      actions.setSize_v3,
      actions.setTextDisplaySettings_v3,
    ],
    async (actions, target, helpers) => {
      const activeBook = helpers.getStoreState().book_v3.activeBook_v3
      if (!activeBook.id) {
        return
      }
      actions.setIsLoading_v3(true)
      await actions.updateBook_v3(activeBook)
    },
  ),
}

export default bookService_v3
