import get from 'lodash/get'
import omit from 'lodash/omit'
import orderBy from 'lodash/orderBy'
import { BOOK_SIZES } from 'pdf-printer/src/components/Page'

import { BOOK_SECTION_TYPES } from 'utils/constants'
import BookValidationError from 'utils/errors/ValidationError'

import {
  CROP_DIRECTION,
  MAX_BOOK_CAPACITY,
  MAX_CUSTOM_BOOK_TITLE_LENGTH,
  MAX_LETTER_BOOK_TITLE_LENGTH,
  MIN_BOOK_CAPACITY,
} from './Book.constants'

/**
 * Returns picture width/height in ordered array based on given rotation angle.
 * @param {object} picture
 * @param {number} rotation
 * @returns {array}
 */
const getPicSides = (picture, rotation) => (
  rotation % 180 === 0 ? [picture.width, picture.height] : [picture.height, picture.width]
)

/**
 * Returns possible book cover position change direction (horizontal/vertical/none)
 * @param {object} size - {coverPtWidth, coverPtHeight}
 * @param {object} picture - chosen picture record
 * @param {number} rotation - rotation angle
 * @returns {string}
 */
const cropDirection = (size, picture, rotation) => {
  if (!size || !picture) {
    return CROP_DIRECTION.NONE
  }

  const [picWidth, picHeight] = getPicSides(picture, rotation)
  const verticalRatio = picHeight / size.coverPtHeight
  const horizontalRatio = picWidth / size.coverPtWidth
  if (verticalRatio === horizontalRatio) {
    return CROP_DIRECTION.NONE
  }
  if (verticalRatio > horizontalRatio) {
    return CROP_DIRECTION.VERTICAL
  }

  return CROP_DIRECTION.HORIZONTAL
}

/**
 * Returns cover position adjusted to avoid 0 position as backend treats it as center cover gravity.
 * @param {object} position - {xPositionPercentage, yPositionPercentage}
 * @param {object} bookSize - {coverPtHeight, coverPtWidth}
 * @param {object} picture - picture record
 * @param {number} rotation - rotation angle
 * @returns {object}
 */
export const adjustBookCoverPosition = (position, bookSize, picture, rotation) => {
  const newPosition = { ...position }
  const direction = cropDirection(bookSize, picture, rotation)
  if (direction === CROP_DIRECTION.VERTICAL && position.yPositionPercentage === 0) {
    newPosition.yPositionPercentage = 0.00001
  } else if (direction === CROP_DIRECTION.HORIZONTAL && position.xPositionPercentage === 0) {
    newPosition.xPositionPercentage = 0.00001
  }

  return newPosition
}

/**
 * Redirects to given or appropriate URL if error message is given or the book have some common reason to redirect
 * @param {object} book - book object returned from request
 * @param {*} id - book id returned from route query
 * @param {string} redirectMsg - override default redirect message
 * @param {object} res - res object returned from app context
 * @param {*} router - next router object
 * @returns {boolean}
 */
export const handleBookErr = ({ book, id, redirectMsg = '', res, router }) => {
  const coverRequired = router.asPath.includes('checkout')
  const hasCover = !!book?.id && !!book?.cover?.sections && getImagePageSection(book.cover.sections).picture?.id

  // handle empty book & errors
  let redirectUrl = '/books'
  if (!book || parseInt(book.id) !== parseInt(id)) {
    redirectMsg = 'We were unable to find your book'
  }

  if (!!coverRequired && !hasCover) {
    redirectMsg = 'Please add a cover photo'
    redirectUrl = `/books/${id}/pages/0`
  }

  if (redirectMsg !== '') {
    if (res) {
      res.writeHead(307, { location: `${redirectUrl}?error=${redirectMsg}` })
      res.end()
      return true
    } else if (typeof window !== 'undefined') {
      router.push(`${redirectUrl}?error=${redirectMsg}`)
      return true
    }
    return false
  }
}

/**
 *
 * @param {object} bookSizes
 * @param {number} productId
 * @returns {number}
 */

export const maxBookTitleLength = (bookSizes, productId) => (
  (productId === bookSizes?.large?.id || productId === BOOK_SIZES.LARGE.id) ?
    MAX_LETTER_BOOK_TITLE_LENGTH : MAX_CUSTOM_BOOK_TITLE_LENGTH
)

export const BOOK_LIMIT_ERROR_TYPES = {
  MAX_PAGES_LIMIT_REACHED: 'max_pages_limit',
  MIN_PAGES_LIMIT_REACHED: 'min_pages_limit',
  PURCHASED_PAGES_LIMIT_REACHED: 'purchased_pages_limit',
}

export const validateBookPagesLimit = (totalPagesCount, {
  minCapacity = MIN_BOOK_CAPACITY,
  maxCapacity = MAX_BOOK_CAPACITY,
  purchasedLimit = Infinity,
}) => {
  if (totalPagesCount < minCapacity) {
    throw new BookValidationError(BOOK_LIMIT_ERROR_TYPES.MIN_PAGES_LIMIT_REACHED)
  }
  if (totalPagesCount > maxCapacity) {
    throw new BookValidationError(BOOK_LIMIT_ERROR_TYPES.MAX_PAGES_LIMIT_REACHED)
  }
  if (totalPagesCount > purchasedLimit) {
    throw new BookValidationError(BOOK_LIMIT_ERROR_TYPES.PURCHASED_PAGES_LIMIT_REACHED)
  }
}

/**
 *
 * @param {number} totalPicturesCount
 * @param {number} minCapacity
 * @param {number} maxCapacity
 * @param {number} purchasedLimit
 * @returns {object,undefined}
 */
export const picturesValidationMessage = (totalPicturesCount, {
  minCapacity = MIN_BOOK_CAPACITY,
  maxCapacity = MAX_BOOK_CAPACITY,
  purchasedLimit = Infinity,
} = {}) => {
  if (totalPicturesCount < minCapacity) return { key: 'book.pictures.more_than', opts: { count: minCapacity } }
  if (totalPicturesCount > maxCapacity) return { key: 'book.pictures.less_than', opts: { count: maxCapacity } }
  if (totalPicturesCount > purchasedLimit) return { key: 'book.pictures.less_than', opts: { count: purchasedLimit } }
}

/**
 *
 * @param {array} pages
 * @param {array} sortKeys
 * @param {array} sortValues
 * @returns {array}
 */
export const sortPages = (pages, sortKeys, sortValues) => {
  sortKeys = sortKeys.map((key) => {
    if (typeof key !== 'string') return key

    return (item) => get(getImagePageSection(item.sections).picture, key)
  })
  return orderBy(pages, sortKeys, sortValues)
}

/**
 *
 * @param {object} param0
 * @returns {boolean}
 */
export const isReadOnly = ({ isConcierge, isPurchased }) => !isConcierge || isPurchased

/**
 * @param {object} book
 * @returns {boolean}
 */
export const validAmountOfPrepaidPages = (book) => {
  const numberOfPicture = book.pages?.length ?? book.numberOfPages ?? 0
  return numberOfPicture >= (book.prepaidPages || 0)
}

/**
 * @param {object} cover
 * @returns {boolean}
 */
export const hasCover = (cover) => !!cover && !!getImagePageSection(cover?.sections || [])?.picture?.id

/**
 *
 * @param {object} book
 * @returns {boolean}
 */
export const isSentToCustomer = (book) => !book.shell && book.isConcierge

/**
 * Returns wether book is valid to be sent/resent to customer by admin
 * NOTE: it is equivalent to check in the admin app
 * @param {object} book
 * @returns {boolean}
 */
export const canBeSentToCustomer = (book) => !isReadOnly(book) && validAmountOfPrepaidPages(book)

export const getPageSectionIndex =
  (sections, sectionType) => sections ? sections.findIndex((item) => item.sectionType === sectionType) : -1

export const getTextPageSection =
  (sections) => sections.find((section) => section.sectionType === BOOK_SECTION_TYPES.TEXT)

export const getImagePageSection =
  (sections) => sections.find((section) => section.sectionType === BOOK_SECTION_TYPES.IMAGE)

const KEYS_TO_OMIT_FROM_BOOK = ['prepaidPages', 'prepaidQuantity']

const normalizeBookPrivilege = (book, isAdmin) => (
  isAdmin ? book : omit(book, KEYS_TO_OMIT_FROM_BOOK)
)

const normalizeSections = (sections) => (
  sections.map(({ picture, ...rest }) => omit((
    picture?.id
      ? { ...rest, picture, pictureId: picture.id }
      : rest
  ), 'id'))
)

export const normalizeBookForReorder = (book) => {
  const { pages, cover, ...restBook } = book

  const normalizedPages = pages.map(({ sections, ...page }) => ({
    ...omit(page, 'id'),
    sections: normalizeSections(sections.map((section) => omit(section, 'id'))),
  }))

  const normalizedCover = {
    ...omit(cover, 'id'),
    sections: normalizeSections(cover?.sections.map((section) => omit(section, 'id'))),
  }

  const privilegedBook = normalizeBookPrivilege(omit(restBook, ['id', 'createdAt', 'updatedAt', 'isInvoiced']), false)

  return {
    ...normalizeBookPageImages({ ...privilegedBook, pages: normalizedPages, cover: normalizedCover }),
    isPurchased: false,
  }
}

const normalizeBookPageImages = (book) => {
  return {
    ...book,
    pages: book.pages.map((page) => {
      return {
        ...page,
        sections: page.sections.map((section) => {
          if (section.picture?.id) {
            return {
              ...section,
              pictureId: section.picture.id,
            }
          }
          return section
        }),
      }
    }),
  }
}

export const clearBookPagesSectionsId = (pages) => {
  return pages.map((page) => ({
    ...omit(page, 'id'),
    sections: page.sections.map((section) => {
      const withoutIdSection = omit(section, 'id')
      if (section.picture?.id) {
        return {
          ...withoutIdSection,
          pictureId: section.picture.id,
        }
      }
      return withoutIdSection
    }),
  }),
  )
}

export const getPageRenderKey = (page) => {
  return page.sections.reduce((acc, section) => `${acc}-${section.picture?.id}`, '')
}

export const prepareBookForUpdate = (book, pagesOrderIds) => {
  const { pages, cover, ...restBook } = omit(
    book,
    ['createdAt', 'updatedAt', 'isInvoiced', 'size', ...KEYS_TO_OMIT_FROM_BOOK],
  )
  const normalizedPages = pages.map(({ sections, ...page }, i) => ({
    ...page,
    id: pagesOrderIds[i],
    sections: normalizeSections(sections).map(({ picture, ...rest }) => rest),
  }))

  const normalizedCover = omit(
    cover ? {
      ...cover,
      sections: normalizeSections(cover.sections).map(({ picture, ...rest }) => rest),
    } : cover,
    'id',
  )
  if (normalizedCover) delete restBook.title

  return { ...restBook, pages: normalizedPages, cover: normalizedCover }
}

export const createPage = (picture, includePicture) => ({
  sections: [
    {
      sectionType: BOOK_SECTION_TYPES.IMAGE,
      pictureId: picture.id,
      ...(includePicture && { picture }),
    },
  ],
  // Temporarily using picture.id for correct page rendering until the true ID is provided by the backend.
  ...(includePicture && { id: picture.id }),
})
