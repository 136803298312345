import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import { useStoreActions, useStoreState } from 'easy-peasy'

import Button from 'components/Buttons/Button'
import Icon from 'components/Icon'
import P from 'components/Text/P'
import UploadPicture from 'components/UploadPicture'
import { useAppIsLoading } from 'dux/slice/ui'

import ReplaceImageStyle from './ReplaceImage.style'

const ReplaceImage = ({ onClose, picture }) => {
  const updatePicture = useStoreActions((actions) => actions.pictures.updatePicture)
  const isLoading = useAppIsLoading()
  const picturesLoading = useStoreState((state) => state.pictures.isLoading)

  const handleClose = useCallback(() => {
    onClose(picture.id)
  }, [onClose, picture.id])

  const replaceAction = async (files) => {
    await updatePicture({ picture: { id: picture.id, image: files[0] } })

    onClose()
  }

  return (
    <ReplaceImageStyle style={{ display: isLoading ? 'none' : 'block' }}>
      <div className={'buttons'}>
        <P onClick={handleClose} className={'p white desktop-btn'}>Cancel</P>

        <Button onClick={handleClose} className={'mobile-btn'}>
          Cancel
        </Button>
      </div>

      <div className={'notice hide-mobile'}>
        <Icon name={'error-outline'} className={'white'} />
        <P className={'p white'}>Replacing this image will remove it from your account</P>
      </div>

      <div className={'content-wrap'}>
        <div className={'notice hide-desktop'}>
          <Icon name={'error-outline'} className={'darkblue'} />
          <P className={'sm darkblue'}>Replacing this image will remove it from your account</P>
        </div>
        <div className={'panel-wrap'}>
          <UploadPicture
            onDrop={replaceAction}
            isLoading={picturesLoading}
          />
        </div>
      </div>
    </ReplaceImageStyle>
  )
}

ReplaceImage.propTypes = {
  onClose: PropTypes.func.isRequired,
  picture: PropTypes.object,
}

export default ReplaceImage
