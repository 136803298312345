import React from 'react'
import PropTypes from 'prop-types'

import NextHead from 'next/head'

// TODO Add correct meta data
export const defaults = {
  title: 'Artkive',
  description: 'Description',
  ogImage: 'https://ogImage',
  url: 'https://url',
}

const Head = ({
  description = defaults.description,
  ogImage = defaults.ogImage,
  title = defaults.title,
  url = defaults.url,
}) => {
  return (
    <>
      <NextHead>
        <meta charSet={'UTF-8'} />
        <title>{title || defaults.title}</title>
        <meta name={'description'} content={description || defaults.description} />
        <meta name={'viewport'} content={'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'} />
        <link rel={'apple-touch-icon'} sizes={'180x180'} href={'/images/apple-touch-icon.png'} />
        <link rel={'mask-icon'} href={'/images/safari-pinned-tab.svg'} color={'#099FFF'} />
        <link rel={'icon'} sizes={'192x192'} href={'/images/favicon-192x192.png'} />
        <link rel={'icon'} sizes={'512x512'} href={'/images/favicon-512x512.png'} />
        <link rel={'icon'} href={'/images/favicon.ico'} />
        <meta property={'og:url'} content={url || defaults.url} />
        <meta property={'og:title'} content={title || defaults.title} />
        <meta property={'og:description'} content={description || defaults.description} />
        <meta name={'twitter:site'} content={url || defaults.url} />
        <meta name={'twitter:card'} content={'summary_large_image'} />
        <meta name={'twitter:image'} content={ogImage || defaults.ogImage} />
        <meta property={'og:image'} content={ogImage || defaults.ogImage} />
        <meta property={'og:image:width'} content={'1200'} />
        <meta property={'og:image:height'} content={'630'} />
      </NextHead>
    </>
  )
}

Head.propTypes = {
  description: PropTypes.string,
  ogImage: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string,
}

export default Head
