import { NOTIFICATION_TYPES } from 'components/Notification'
import { setNotification } from 'dux/slice/ui'
import requestApi from 'utils/api'
import { extractRequestErrorMessage } from 'utils/extractRequestErrorMessage'

const axiosBaseQuery = async ({ url, method, data, params }, api, extraOptions) => {
  try {
    const response = await requestApi.handleRequest({
      method,
      url,
      data,
      params,
    }, {
      legacyResponse: false,
    })

    if (extraOptions?.successMessage) {
      api.dispatch(
        setNotification({
          type: NOTIFICATION_TYPES.SUCCESS,
          message: extraOptions.successMessage,
        }),
      )
    }

    return { data: response.data }
  } catch (err) {
    api.dispatch(
      setNotification({
        type: NOTIFICATION_TYPES.ERROR,
        message: extractRequestErrorMessage(err),
      }),
    )

    return {
      error: {
        status: err.response?.status,
        data: err.response?.data || err,
      },
    }
  }
}

export default axiosBaseQuery
