import { format, toDate } from 'date-fns'
import moment from 'moment/moment'

import { DATE_FORMAT } from './constants'
export const US_DATE_FORMAT = 'MM/DD/YYYY'

export const isMomentValid = (date) => date?._isAMomentObject

// Considering the date as valid if it's after 01-01-1900 and before now
export const isPictureDateValid = (date) => isMomentValid(date) && date?.isValid() && date?.isAfter('1900-01-01') && date?.isBefore(moment())

export const reformatDate = (dateOrString, dateFormat = DATE_FORMAT) => format(toDate(dateOrString), dateFormat)
