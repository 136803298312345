import book_v3 from 'containers/Book/Book_v3.dux'
import checkout from 'containers/Checkout/Checkout.dux'
import pictures from 'containers/Pictures/Pictures.dux'
import user from 'containers/User/User.dux'
import proxyUi from 'dux/ui/proxyUi'

const appModel = {
  book_v3,
  checkout,
  pictures,
  user,
  proxyUi,
}

export default appModel
