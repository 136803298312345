import { styled } from '@mui/material/styles'

import { UploadPicturesWrapper } from 'components/UploadPicture/UploadPicture.style'
import theme from 'styles/theme'

export default styled('div')`
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  min-height: -webkit-fill-available;
  left: 0;
  width: 100vw;
  max-width: 100vw;
  position: absolute;
  top: 0;
  z-index: 9999999999;

  .panel-wrap {
    display: flex;
    width: 100%;
    flex-grow: 1;
    overflow-y: scroll;
  }

  .buttons {
    bottom: 0;
    z-index: 10;
    display: flex;
    justify-content: flex-end;
    width: 100%;

    padding: 5px 40px;
    position: relative;
    background: none;

    * {
      margin: 10px;
      cursor: pointer;
    }

    .mobile-btn {
      display: none;
    }

    @media (max-width: ${theme.breakpoints.md}), (max-height: 850px) {
      position: fixed;
      padding: 0;
      background: ${(props) => props.theme.colors.snowgray};

      .desktop-btn {
        display: none;
      }

      .mobile-btn {
        display: block;
      }
    }
  }

  .notice {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 1rem auto;
    text-align: center;
    flex-direction: column;
    width: 80%;

    .icon-error-outline {
      margin-right: 0.5rem;
      margin-bottom: 0;
    }

    /* desktop */
    @media (min-width: ${theme.breakpoints.md}) {
      flex-direction: row;

      .icon-error-outline {
        margin-right: 0.5rem;
        margin-bottom: 0;
      }
    }
  }

  .content-wrap {
    background-color: ${(props) => props.theme.colors.white};
    border: 2px solid ${(props) => props.theme.colors.lightgray};
    border-radius: 0.5rem;
    height: auto;
    overflow-y: scroll;
    padding: 3.75rem 3.75rem 2rem;
    width: 70vw;
    margin-bottom: 80px;
    max-height: 85vh;

    @media (max-width: ${theme.breakpoints.md}), (max-height: 850px) {
      border: none;
      border-radius: 0;
      height: 100vh;
      min-height: -webkit-fill-available;
      width: 100vw;
      padding: 1rem;
      position: absolute;
      top: 0;
      left: 0;
    }

    @media (max-width: ${theme.breakpoints.lg}), (max-height: 850px) {
      margin-bottom: 40px;
      padding: 1.5rem;

      ${UploadPicturesWrapper} {
        padding: 1.5rem;
      }
    }
  }
`
