export const TAG_TYPE = {
  BOOKS: 'Books',
  USER: 'User',
  NAME: 'NameTextLabel',
  AGE_GRADE: 'AgeGradeTextLabel',
  TAG: 'TagTextLabel',
  DRAFTS: 'Drafts',
  CHILD_NAME_WITH_AGE_GRADE: 'ChildNameWithAgeGrade',
  ORDER: 'Order',
  ORDER_RECEIPT: 'OrderReceipt',
  ORDER_HISTORY: 'OrderHistory',
}
