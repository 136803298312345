import React, { forwardRef, useCallback } from 'react'
import PropTypes from 'prop-types'

import FormHelperText from '@mui/material/FormHelperText'
import IconButton from '@mui/material/IconButton'
import InputAdornment from '@mui/material/InputAdornment'
import InputLabel from '@mui/material/InputLabel'
import OutlinedInput from '@mui/material/OutlinedInput'

import useCombinedRef from 'hooks/useCombinedRef'

import InputStyle from './Input.style'

const Input = forwardRef((props, ref) => {
  const {
    autoComplete,
    autoFocus,
    className,
    defaultValue,
    helperHint,
    error,
    gutter,
    icon,
    label,
    onClick,
    onSubmit,
    size,
    innerRef,
    maxLength,
    inputProps = {},
    variant = 'legacy',
    pattern,
    ...rest
  } = props

  const handleKeyPress = (e) => e.key === 'Enter' && onSubmit && onSubmit()
  const errorMessage = typeof error === 'string' ? error : error?.message
  const inputRef = useCombinedRef(ref, innerRef)

  let inputAdornmentIcon = icon

  const iconClickHandler = useCallback(() => {
    inputRef.current.focus()
  }, [inputRef])

  const innerInputProps = {
    ...inputProps,
    pattern,
    maxLength,
  }

  return (
    <InputStyle className={className} size={size} gutter={gutter}>
      {label && (<InputLabel>{label}</InputLabel>)}
      <OutlinedInput
        variant={variant}
        endAdornment={inputAdornmentIcon && (
          <InputAdornment position={'end'} className={'input-adornment'}>
            <IconButton onClick={iconClickHandler} size={'small'}>
              {inputAdornmentIcon}
            </IconButton>
          </InputAdornment>
        )}
        error={!!error}
        margin={'none'}
        {...rest}
        inputProps={innerInputProps}
        inputRef={inputRef}
        onKeyPress={handleKeyPress}
        sx={{ my: 0 }}
      />
      <FormHelperText error={!!error} sx={{ mt: 0, fontSize: '0.75rem' }}>
        {!error && helperHint}
        {!!error && errorMessage}
      </FormHelperText>
    </InputStyle>
  )
})

Input.displayName = 'Input'

Input.propTypes = {
  autoComplete: PropTypes.string,
  autoFocus: PropTypes.bool,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  helperHint: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  label: PropTypes.string,
  name: PropTypes.string,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  onClick: PropTypes.func,
  onFocus: PropTypes.func,
  onSubmit: PropTypes.func,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  icon: PropTypes.node,
  innerRef: PropTypes.object,
  size: PropTypes.string,
  gutter: PropTypes.bool,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  inputProps: PropTypes.object,
  variant: PropTypes.oneOf(['new', 'legacy']),
  pattern: PropTypes.string,
}

export default Input
